import React from "react";

import styles from "./topics.module.css";

interface IProps {
    title: string;
    description: string;
    bgColor: string;
    icon: string;
}

const TopicItem: React.FC<IProps> = ({
    title,
    description,
    bgColor,
    icon,
}) => {
    return (
        <div className={styles.topic}>
            <div className={styles.topic_heading}>
                <div className={styles.topic_icon} style={{ backgroundColor: bgColor}}>
                    <img
                        src={icon}
                        alt="Topic"
                        width={24}
                        height={24}
                    />
                </div>
                <span>{title}</span>
            </div>

            <p>{description}</p>

            {/* <div className={styles.topic_footer}>
                <span>Learn more</span>
                <img
                    src="/images/icons/arrow-right-purple.svg"
                    alt="Facebook"
                    width={15}
                    height={15}
                />
            </div> */}
        </div>
    );
};

export default TopicItem;