"use client";
import React from "react";
import ReactPlayer from 'react-player/lazy';

import styles from "./video.module.css";

const Video: React.FC = () => {
    const [playing, setPlaying] = React.useState(false);

    return (
        <div className={styles.video_container}>
            <div className={styles.video}>
                <h3>Watch video</h3>

                <div className={styles.play_btn_container}>
                    <div className={styles.play_btn} onClick={() => setPlaying(!playing)}>
                        <img 
                            src={playing ? "/images/icons/pause.svg" : "/images/icons/play.svg"}
                            width={26}
                            height={26}
                            alt="lock"
                        />
                    </div>
                </div>

                <ReactPlayer
                    height={500}
                    width={'80%'}
                    playing={playing}
                    className={styles.react_player}
                    url='https://youtu.be/htwoob3jfY8'
                />
            </div>
        </div>
    );
};

export default Video;