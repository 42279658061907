import React from "react";
import ReactPlayer from 'react-player/lazy';

import styles from "./playlist.module.css";
import { Button } from "../..";
import videos from "../../../constants/videos";

const Playlist: React.FC = () => {
    const [playing, setPlaying] = React.useState(false);
    const [selected, setSelected] = React.useState<string>("1");

    return (
        <div className={styles.playlist_container}>
            <div className={styles.playlist}>
                <div className={styles.playlist_list}>
                    {videos.map((video, index) => (
                        <div
                            key={index}
                            className={`
                                ${styles.playlist_item}
                                ${selected === video.id ? styles.playlist_item_selected : ""}
                            `}
                            onClick={() => setSelected(video.id)}
                        >
                            <img
                                width={156}
                                height={100}
                                alt={video.title}
                                src={video.thambnail}
                                className={styles.playlist_item_image}
                            />

                            <div className={styles.playlist_item_info}>
                                <h4>{video.title}</h4>
                                <p>{video.description}</p>
                            </div>
                        </div>
                    ))}
                </div>

                <div className={styles.playlist_video}>
                    <ReactPlayer
                        height={500}
                        width={'100%'}
                        playing={playing}
                        className={styles.react_player}
                        url={videos.find(video => video.id === selected)?.video}
                    />

                    <Button
                        type="primary"
                        icon={playing ? "/images/icons/pause.svg" : "/images/icons/play.svg"}
                        customStyle={{ width: '100%' }}
                        onClick={() => setPlaying(!playing)}
                    />
                </div>
            </div>
        </div>
    );
};

export default Playlist;