import React from "react";

import styles from "./steps.module.css";
import StepItem from "./StepItem";

const Steps: React.FC = () => {
  return (
    <div className={styles.steps_container}>
      <div className={styles.steps}>
        <h2>How it works?</h2>

        <div className={styles.steps_list}>
          <StepItem
            bgColor="#F4EBFF"
            icon="/images/icons/guru.svg"
            title="Create your account"
            subTitle="Create your account today. Join our platform and let our algorithm tailor a personalized learning path just for you."
          />

          <StepItem
            bgColor="#E0EAFF"
            icon="/images/icons/certificate.svg"
            title="Get certified"
            subTitle="Attend your course and validate your skills with a globally recognized credential."
          />

          <StepItem
            bgColor="#FCE7F6"
            icon="/images/icons/3d-cube.svg"
            title="Unlock your future"
            subTitle="Land the career of your dreams as a digital nomad or gain access to the job or promotion you've always envisioned."
          />
        </div>
      </div>
    </div>
  );
};

export default Steps;
