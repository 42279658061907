import styles from "./faqs.module.css";
import FaqItem from "./FaqItem";
import { faqs } from "../../../../constants";

export default function Faqs() {
    return (
        <div className={styles.course_faqs} id="faqs">
            <div className={styles.course_faqs_content}>
                <div className={styles.course_faqs_info}>
                    <h4 className={styles.course_faqs_subtitle}>Faqs</h4>
                    <h3 className={styles.course_faqs_title}>Still having a doubt ? We&apos;ll explain more !</h3>
                </div>

                <div className={styles.course_faqs_items}>
                    {faqs.map((faq, index) => (
                        <FaqItem 
                            key={index}
                            faq={faq} />
                    ))}
                </div>
            </div>
        </div>
    );
}