import React from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import env from "../../../../config/env";

interface LocationInputProps {
  location: any;
  setLocation: (location: any) => void;
}

const LocationInput: React.FC<LocationInputProps> = ({
  location,
  setLocation,
}: LocationInputProps) => {
  const CustomInput = React.forwardRef<any, any>(
    ({ value, onClick, placeholder }, ref) => (
      <input
        className="custom-input"
        onClick={onClick}
        ref={ref}
        placeholder={placeholder}
        value={value}
        onChange={() => {}}
      />
    )
  );

  return (
    <GooglePlacesAutocomplete
      apiKey={env.GOOGLE_MAPS_API_KEY}
      selectProps={{
        onChange: (place: any) => setLocation(place),
        placeholder: "Your city",
        value: location || undefined,
        styles: {
          input: (provided) => ({
            ...provided,
            height: "100%",
          }),

          dropdownIndicator: (provided) => ({
            display: "none",
          }),
          menu: (provided) => ({
            ...provided,
            zIndex: 9999,
          }),
          valueContainer: (provided) => ({
            ...provided,
          }),
          control: (provided) => ({
            ...provided,
            border: "1px solid #cad4e0",
            borderRadius: "8px",
            height: "3rem",
          }),
        },
      }}
    />
  );
};

export default LocationInput;
