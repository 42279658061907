import { useState } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";

import styles from "./header.module.css";
import { Button, Popup } from "../../../../components";
import { useStore } from "../../../../store";
import env from "../../../../config/env";

interface IProps {
  type: "course" | "cohort";
}

function CourseHeader({ type }: IProps) {
  const navigate = useNavigate();
  const { courseStore, userStore } = useStore();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const course: any = courseStore.courseDetails;
  const image =
    type === "cohort" ? course?.SessionImage?.name : course?.image?.name;
  const endTime = course?.endTime
    ? new Date(course.endTime).toLocaleDateString()
    : null;
  const startTime = course?.startTime
    ? new Date(course.startTime).toLocaleDateString()
    : null;

  const lookForSessions = async () => {
    courseStore.selectCourse(course.id);

    if (userStore.user) {
      await courseStore.lookForSessions(course.id);
      navigate("/search");
    } else {
      setIsOpen(true);
    }
  };

  return (
    <div
      className={styles.course_header}
      style={{ backgroundImage: "url('/home-vector.svg')" }}
    >
      <div className={styles.course_header_content}>
        <div className={styles.course_header_description}>
          {course?.domains?.length > 0 && (
            <div className={styles.tag}>
              <span>{course?.domains[0]?.name ?? course.domains[0]}</span>
            </div>
          )}

          <h1>{course?.title}</h1>
          <p>{course?.description}</p>
          {type === "cohort" && (
            <span className={styles.priceText}>{course?.price} TDN</span>
          )}

          <div className={styles.course_header_details_container}>
            {type === "course" && course.courseCreator ? (
              <div className={styles.course_header_details}>
                <span>
                  <span>Created by: </span>
                  <span className={styles.creatorText}>
                    {course.courseCreator}
                  </span>
                </span>
              </div>
            ) : null}

            {type === "course" ? (
              <div className={styles.course_header_details}>
                <div className={styles.course_header_details_item}>
                  <img
                    width={24}
                    height={24}
                    alt="Star Icon"
                    src="/images/icons/star.svg"
                  />
                  <span style={{ fontWeight: "600" }}>{course?.rating}</span>
                  <span style={{ textDecoration: "underline" }}>
                    ({course?.ratingsCount} reviews)
                  </span>
                </div>
              </div>
            ) : null}

            {type === "cohort" &&
            (course?.isOnline || course?.sessionBooking?.length > 0) ? (
              <div className={styles.course_header_details}>
                <img
                  width={24}
                  height={24}
                  alt="Star Icon"
                  src="/images/icons/location-gray.svg"
                />
                <span>{course?.isOnline ? "Online" : "Synergy Space"}</span>
              </div>
            ) : null}

            {type === "cohort" && startTime && endTime ? (
              <div className={styles.course_header_details}>
                <img
                  width={24}
                  height={24}
                  alt="Star Icon"
                  src="/images/icons/date.svg"
                />
                <span>
                  {startTime} To {endTime}
                </span>
              </div>
            ) : null}
          </div>

          <div className={styles.cta_container}>
            {/* <Button
                            text="Wishlist"
                            type="secondary"
                            icon="/images/icons/heart.svg"
                            onClick={() => { }}
                        /> */}
            {type === "course" && (
              <Button
                type="primary"
                text="View upcoming cohorts"
                onClick={() => lookForSessions()}
              />
            )}
          </div>
        </div>

        <img
          alt={course?.title}
          src={`${env.IMAGE_API}${image}`}
          className={styles.course_header_image}
        />
      </div>

      {isOpen && (
        <Popup
          type={type}
          isOpen={isOpen}
          course={course.id}
          closeModal={() => setIsOpen(false)}
        />
      )}
    </div>
  );
}

export default observer(CourseHeader);
