import {  useEffect } from "react";
import { observer } from "mobx-react";

import styles from "./form.module.css";
import { useStore } from "../../../../store";

const PaymentMethods = observer((
    {paymentMethod, setPaymentMethod}: {
        paymentMethod: "PostWire" | "Onsite"
        setPaymentMethod: React.Dispatch<React.SetStateAction<"PostWire" | "Onsite">>
    }
) => {
    const {userStore} = useStore();

    useEffect(() => {
        userStore.paymentMethodHandler(paymentMethod);
    }, [paymentMethod]);

    return (
        <div className={styles.payment_methods}>
            <h4>Payment Methods</h4>

            <div className={styles.payment_methods_container}>
                <div className={styles.payment_method}>
                    <input
                        id="Onsite"
                        type="radio"
                        name="payment_method" 
                        value={paymentMethod}
                        checked={paymentMethod === "Onsite"}
                        onChange={() => setPaymentMethod("Onsite")}
                    />
                    <label htmlFor="Onsite">Onsite</label>
                </div>

                <div className={styles.payment_method}>
                    <input
                        id="PostWire"
                        type="radio"
                        name="payment_method"
                        checked={paymentMethod === "PostWire"}
                        onChange={() => setPaymentMethod("PostWire")}
                    />
                    <label htmlFor="PostWire">Poste</label>
                </div>
            </div>

            <div className={styles.payment_methods_security}>
                <img 
                    src="/images/icons/lock.svg"
                    width={28}
                    height={28}
                    alt="lock"
                />
                <span>We protect your payment information using encryption to provide bank-level security.</span>
            </div>
        </div>
    );
});

export default PaymentMethods;