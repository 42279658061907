import React from "react";

import styles from "./blog.module.css";

interface IProps {
    fill?: boolean;
    blog: {
        title: string;
        description: string;
        date: string;
        image: string;
        link: string;
    }
}

const Blog: React.FC<IProps> = ({ fill, blog }) => {
    return (
        <a className={`${fill ? styles.last_blog : styles.blog}`} href={blog.link} target="_blank">
            <div className={styles.blog_picture}>
                <img
                    src={blog.image}
                    alt={blog.title}
                />
            </div>

            <div className={styles.blog_details}>
                <div className={styles.blog_titles}>
                    <span>{blog.date}</span>
                    <h3>{blog.title}</h3>
                </div>
                <p>{blog.description}</p>
            </div>
        </a>
    );
};

export default Blog;