const videos = [
    {
        id: '1',
        title: 'Formez-vous aux métiers du numérique ...',
        description: 'ClassX et Organisation internationale de la Francophonie (OIF) s’associent pour la mise en place du projet D-CLIC pour la formation de 100 jeunes aux métiers du numérique.',
        video: 'https://www.youtube.com/watch?v=kVscAI88UPo',
        thambnail: 'https://i.ytimg.com/vi/kVscAI88UPo/hqdefault.jpg?sqp=-oaymwE2CNACELwBSFXyq4qpAygIARUAAIhCGAFwAcABBvABAfgB1AaAAuADigIMCAAQARhlIFooWDAP&rs=AOn4CLAaJT9xbECa9XjBLtwRhJaTE0y8EQ',
    },
    {
        id: '2',
        title: 'Skander : dévelopeur des jeux videos',
        description: "From the proud parent's perspective, Skander's mom discusses the transformative experience of ClassX's Game Development course.",
        video: 'https://www.youtube.com/watch?v=LvNKKqCZ0xM',
        thambnail: 'https://i.ytimg.com/vi/LvNKKqCZ0xM/hqdefault.jpg?sqp=-oaymwE2CNACELwBSFXyq4qpAygIARUAAIhCGAFwAcABBvABAfgB_gmAAtAFigIMCAAQARhlIFooYTAP&rs=AOn4CLDFkowBpc2d83zd1s3qzpx7IxIbMw',
    },
    {
        id: '3',
        title: 'Meet Siwar : junior game developer',
        description: 'Meet Siwar, a 14 year old prodigy diving into the world of game development.',
        video: 'https://www.youtube.com/watch?v=YwPk0SRpu5E',
        thambnail: 'https://i.ytimg.com/vi/YwPk0SRpu5E/hqdefault.jpg?sqp=-oaymwE2CNACELwBSFXyq4qpAygIARUAAIhCGAFwAcABBvABAfgB_gmAAtAFigIMCAAQARhTIGUoVTAP&rs=AOn4CLCCyYj5PU20wfZZQk7MXlmJmrMPTw',
    },
    {
        id: '4',
        title: 'Adam : Skander : dévelopeur des jeux videos à 12 ans',
        description: 'Join Mrs. Narjess Caulier as she shares the remarkable story of her  12 year old son Adam, who has just conquered the Video Game Development course at ClassX.',
        video: 'https://www.youtube.com/watch?v=0F1P2A7p0PI',
        thambnail: 'https://i.ytimg.com/vi/0F1P2A7p0PI/hqdefault.jpg?sqp=-oaymwE2CNACELwBSFXyq4qpAygIARUAAIhCGAFwAcABBvABAfgB_gmAAtAFigIMCAAQARhlIFIoYTAP&rs=AOn4CLAdWcvUkvNRV1CY9tRLC_tbul0y1g',
    },
    {
        id: '5',
        title: 'Découvrez Amal développeuse de jeux vidéo',
        description: 'Step into the world of Amal, a skilled game developer who honed her craft through the #D_CLIC initiative by the International Organization of La Francophonie (OIF) at ClassX.',
        video: 'https://www.youtube.com/watch?v=5Os_eceKp-s',
        thambnail: 'https://i.ytimg.com/vi/5Os_eceKp-s/hqdefault.jpg?sqp=-oaymwE2CNACELwBSFXyq4qpAygIARUAAIhCGAFwAcABBvABAfgB_gmAAtAFigIMCAAQARgTIGIofzAP&rs=AOn4CLA6ZRRJjZxbwLMMge6APb44bU-T-A',
    },
    {
        id: '6',
        title: 'Our students are full of energy and good vibes !',
        description: 'Discover the story of Jihen Warheni, who has chosen ClassX for her E-Commerce training. Armed with a solid academic foundation in communication, she brings a unique perspective to the digital commerce landscape.',
        video: 'https://www.youtube.com/watch?v=rcd19t8huG8',
        thambnail: 'https://i.ytimg.com/vi/rcd19t8huG8/hqdefault.jpg?sqp=-oaymwE2CNACELwBSFXyq4qpAygIARUAAIhCGAFwAcABBvABAfgB1AaAAuADigIMCAAQARgZICUofzAP&rs=AOn4CLA_0YP2z1cT3CEZ_Nu5IUNaLW6Xng',
    },
    {
        id: '7',
        title: 'Découvrez nos talents : Dorra et Skander',
        description: 'Découvrez nos talents : Dorra et Skander, développeurs, ,formés dans le cadre du programme #D_CLIC de Organisation internationale de la Francophonie (OIF) à ClassX.',
        video: 'https://www.youtube.com/watch?v=JkNKhpTeW0Q',
        thambnail: 'https://i.ytimg.com/vi/JkNKhpTeW0Q/hqdefault.jpg?sqp=-oaymwE2CNACELwBSFXyq4qpAygIARUAAIhCGAFwAcABBvABAfgB_gmAAtAFigIMCAAQARgRIGAocjAP&rs=AOn4CLAnZ4NAl3junr1FJUO899Vni2Hs7w',
    },
];

export default videos;
