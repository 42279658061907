import React, { ChangeEventHandler } from "react";

import styles from "./select.module.css";

interface InputProps {
  label: string;
  value?: string;
  options: any[];
  fullWidth?: boolean;
  onChange: ChangeEventHandler<HTMLSelectElement>;
}

const Select: React.FC<InputProps> = ({
  fullWidth,
  label,
  options,
  value,
  onChange,
}) => {
  return (
    <select
      value={value}
      className={styles.select}
      onChange={onChange}
      style={fullWidth ? { width: "100%" } : {}}
    >
      <option value="" disabled>
        {label}
      </option>
      {options.map((option) => (
        <option
          key={option.id || option.value}
          value={option.label ? option.label : option.name}
        >
          {option.label ? option.label : option.name}
        </option>
      ))}
    </select>
  );
};

export default Select;
