import styles from "./path.module.css";

function Icon() {
    return (
        <img
            src="/images/icons/arrow-right.svg"
            alt="arrow-left"
            width={16}
            height={16}
        />
    );
}

export default function PaymentPath() {
    return (
        <div className={styles.payment_path}>
            <span>Home</span>
            <Icon />
            <span>Courses</span>
            <Icon />
            <span>Web development</span>
            <Icon />
            <span>Cohorts</span>
            <Icon />
            <span>25 June 2023</span>
            <Icon />
            <span className={styles.current_page}>Checkout</span>
        </div>
    );
}