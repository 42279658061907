import { useEffect } from "react";

import styles from "./search.module.css";
import { Footer, Navbar, Playlist, SearchComponents } from "../../../components";
import { observer } from "mobx-react";
import { useStore } from "../../../store";

function Search() {
    const { courseStore, userStore } = useStore();

    useEffect(() => {
        courseStore.getTopRatedCourses();
        courseStore.getDomains();
        courseStore.getCategories();
        if (!courseStore.isNew && courseStore.searchedCourses.length === 0) {
            courseStore.searchCourses(6, {});
        }
        userStore.getUser();
    }, [courseStore, userStore]);
    
    return (
        <div>
            <Navbar />

            <header className={styles.header}>
                <div className={styles.header_content}>
                    <h1>Discover all of our Courses</h1>
                    <p>Join our famous classes, the knowledge provided will definitely be useful for you.</p>
                </div>
            </header>
            
            <div className={styles.search_section}>
                <SearchComponents.Filter />
                <SearchComponents.SearchedCourses />
            </div>

            <Playlist />

            <SearchComponents.Categories />

            <Footer />
        </div>
    );
}

export default observer(Search);