import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import env from "../../../../config/env";

import styles from "./header.module.css";
import { useStore } from "../../../../store";
import { Button, Input, Navbar, Select } from "../../..";

type Option = {
  value: string;
  label: string;
};

const Header: React.FC = () => {
  const navigate = useNavigate();
  const { courseStore } = useStore();

  const [search, setSearch] = useState<string>("");
  const [location, setLocation] = useState<Option | null>(null);
  const [category, setCategory] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const categories = courseStore?.domains;

  const handleSearch = (event: any) => {
    event.preventDefault();

    setLoading(true);

    courseStore
      ?.searchCourses(6, {
        search: search,
        location: location?.label ?? "",
        categoryId: category,
      })
      .then(() => {
        navigate("/search");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onPlaceSelected = async (value: Option | null) => {
    setLocation(value);
  };

  return (
    <div
      className={styles.header}
      style={{ backgroundImage: "url('/images/home-vector.png')" }}
    >
      <Navbar isTransparent />

      <div className={styles.header_wrapper}>
        <div>
          <form
            id="filter-form"
            onSubmit={handleSearch}
            className={styles.search_content}
          >
            <div className="w-full flex flex-col md:flex-row justify-start items-center space-x-0 md:space-x-2 space-y-4 md:space-y-0">
              <div className="min-w-full md:min-w-[25rem]">
                <Input
                  label={"Search"}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>

              <Select
                label={"Category"}
                value={category}
                options={categories}
                onChange={(e) => setCategory(e.target.value)}
              />
              <div className="h-full w-full">
                <GooglePlacesAutocomplete
                  apiKey={env.GOOGLE_MAPS_API_KEY}
                  selectProps={{
                    onChange: onPlaceSelected,
                    placeholder: "Your city",
                    value: location || undefined,
                    styles: {
                      input: (provided) => ({
                        ...provided,
                        height: "100%",
                      }),

                      dropdownIndicator: (provided) => ({
                        display: "none",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                      }),
                      control: (provided) => ({
                        ...provided,
                        border: "1px solid #cad4e0",
                        borderRadius: "8px",
                        height: "3rem",
                      }),
                    },
                  }}
                />
              </div>
            </div>
            <div className="w-full md:w-auto flex justify-center items-center">
              <button
                type="submit"
                disabled={loading}
                className={
                  loading ? styles.search_button_disabled : styles.search_button
                }
              >
                Search
              </button>
            </div>
          </form>
        </div>

        <header className={styles.header_container}>
          <div className={styles.header_content}>
            <div className={styles.header_text}>
              <h1>
                Unlock{" "}
                <span className={styles.header_text_colored}>
                  Limitless Learning
                </span>
                <br />
                Opportunities in{" "}
                <span className={styles.header_text_colored}>
                  {new Date().getFullYear()}
                </span>{" "}
              </h1>
              <p>
                Elevate Your Life with Expert-Led Skill Development! Accessing
                top-notch courses is now more attainable than ever.
              </p>
              <span>
                Embrace the Future with GenAI - The Fastest Growing Skill of{" "}
                {new Date().getFullYear()}.
              </span>
            </div>

            <div className="flex items-center justify-center space-x-1 md:space-x-8">
              <Button
                type="primary"
                text="Start Learning"
                onClick={() => {
                  navigate("/search");
                }}
              />
              <Button
                type="secondary"
                text="Explore our courses"
                onClick={() => {
                  navigate("/search");
                }}
              />
            </div>

            <div className={styles.header_categories}>
              <div className={styles.header_category}>
                <img
                  src="/images/icons/speak.svg"
                  alt="design icon"
                  width={32}
                  height={32}
                />
                <span>Learn in groups</span>
              </div>
              <div className={styles.header_category}>
                <img
                  src="/images/icons/bag.svg"
                  alt="design icon"
                  width={32}
                  height={32}
                />
                <span>Access Top Skills</span>
              </div>
              <div className={styles.header_category}>
                <img
                  src="/images/icons/idea.svg"
                  alt="design icon"
                  width={32}
                  height={32}
                />
                <span>Get your certificate on blockchain</span>
              </div>
            </div>
          </div>

          <div className={styles.header_image}>
            <img
              src="/images/home.png"
              alt="we code cover"
              className={styles.image}
            />
          </div>
        </header>
      </div>
    </div>
  );
};

export default observer(Header);
