import React from "react";

import styles from "./steps.module.css";

interface IProps {
    icon: string;
    title: string;
    subTitle: string;
    bgColor: string;
}

const StepItem: React.FC<IProps> = ({
    icon,
    title,
    subTitle,
    bgColor
}) => {
    return (
        <div className={styles.step}>
            <div
                className={styles.icon_container}
                style={{ backgroundColor: bgColor }}
            >
                <img
                    alt="partner"
                    loading="lazy"
                    src={icon}
                    height={52}
                    width={52}
                    style={{
                        objectFit: 'cover',
                    }}
                />
            </div>
            
            <div className={styles.text_container}>
                <span className={styles.title}>{title}</span>
                <span className={styles.subTitle}>{subTitle}</span>
            </div>
        </div>
    );
};

export default StepItem;
