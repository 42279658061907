const RequestRows: React.FC<{ data: any[]}> = ({ data }) => {
    const handleDate = (date: Date) => {
        return date.toString().substring(0, 10).replace(/-/g, "/");
    }

    const handleStatus = (status: number) => {
        switch (status) {
            case 1:
                return <div style={{color: "rgb(0, 191, 165)"}}>Accepted</div>;
            case 2:
                return <div style={{color: "rgb(230, 65, 61)"}}>Rejected</div>;
            default:
                return <div style={{color: "rgb(255, 138, 101)"}}>Pending</div>;
        }
    }

    return (
        <>
            {data.map((request, index) => (
                <tr key={index}>
                    <td className="name">{request.bookingRoom.name}</td>
                    <td>{handleDate(request.createdAt)}</td>
                    <td>{request.bookingSession?.tutors?.fullName ?? ""}</td>
                    <td>{handleDate(request.bookingSession.from)} <br /> {handleDate(request.bookingSession.to)}</td>
                    <td>{request.time}</td>
                    <td>{request.places}</td>
                    <td className="status">{handleStatus(request.status)}</td>
                </tr>            
            ))}
        </>
    );
}

export default RequestRows;