import { useEffect } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";

import { CourseComponents, Footer, Navbar } from "../../../components";
import { useStore } from "../../../store";

function Cohorte() {
  const params = useParams();
  const { courseStore, userStore } = useStore();

  const token = localStorage.getItem("token");

  useEffect(() => {
    window.scrollTo(0, 0);
    if (params.id) {
      courseStore.getCohortById(params.id);
      courseStore.getInstructorById(params.id);
    }
    userStore.getUser();
  }, []);

  return (
    <>
      <Navbar />

      <CourseComponents.CourseHeader type="cohort" />
      <CourseComponents.CourseNavBar type="cohort" />

      <CourseComponents.AboutCourse />
      <CourseComponents.CourseContent />
      {courseStore.instructorDetails && token ? (
        <CourseComponents.AboutInstructor />
      ) : null}
      <CourseComponents.FAQs />

      <Footer />
    </>
  );
}

export default observer(Cohorte);
