import React from "react";
import Modal from "react-modal";
import "react-toastify/dist/ReactToastify.css";
import { observer } from "mobx-react";
import { Button, Select } from "src/components/shared";
import styles from "./popup.module.css";
import { useNavigate } from "react-router-dom";

interface IProps {
  isOpen: boolean;
  sessions: any[];
  courses: any[];
}

const Popup: React.FC<IProps> = ({ isOpen, sessions, courses }) => {
  const navigate = useNavigate();

  const [selectedOption, setSelectedOption] = React.useState("");
  const [selectedSession, setSelectedSession] = React.useState("");
  const [selectedCourse, setSelectedCourse] = React.useState("");

  const options = [
    { value: "1", label: "Create your own course" },
    { value: "2", label: "Create session from a previous session" },
    { value: "3", label: "Create session from an existing course" },
  ];

  const sessionsList = sessions.map((session) => ({
    value: session.id,
    label: session.title,
  }));

  const coursesList = courses.map((course) => ({
    value: course.id,
    label: course.title,
  }));

  const handleCancel = () => {
    navigate(-1);
  };

  const handleNext = () => {
    switch (selectedOption) {
      case "Create your own course":
        navigate("/newCourse");
        break;
      case "Create session from a previous session":
        const session: any = sessionsList.find(
          (s: any) => s.label === selectedSession
        );
        navigate(`/newSession/${session.value}`);
        break;
      case "Create session from an existing course":
        const course: any = coursesList.find(
          (c: any) => c.label === selectedCourse
        );
        navigate(`/newSession/${course.value}`);
        break;
      default:
        break;
    }
  };

  return (
    <Modal isOpen={isOpen} style={customStyles}>
      <div className={styles.content}>
        <h1>Adding a new session/course</h1>

        <div className="field">
          <label htmlFor="option">Please select one option</label>
          <div className="input-field">
            <div className="input">
              <Select
                label="No Option Selected"
                fullWidth
                options={options}
                value={selectedOption}
                onChange={(e) => setSelectedOption(e.target.value)}
              />
            </div>
          </div>
        </div>

        {selectedOption === "Create session from a previous session" && (
          <div className="field">
            <h2>Previous Session</h2>
            <label htmlFor="option">
              Please select a previously taught session
            </label>
            <div className="input-field">
              <div className="input">
                <Select
                  label="No Session Selected"
                  fullWidth
                  options={sessionsList}
                  value={selectedSession}
                  onChange={(e) => setSelectedSession(e.target.value)}
                />
              </div>
            </div>
          </div>
        )}

        {selectedOption === "Create session from an existing course" && (
          <div className="field">
            <h2>Previous Course</h2>
            <label htmlFor="option">Please select an existing course</label>
            <div className="input-field">
              <div className="input">
                <Select
                  label="No Course Selected"
                  fullWidth
                  options={coursesList}
                  value={selectedCourse}
                  onChange={(e) => setSelectedCourse(e.target.value)}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      <div className={styles.button_container}>
        <Button type="secondary" text="Cancel" onClick={handleCancel} />
        <Button
          type="primary"
          text="Next"
          onClick={handleNext}
          isDisabled={
            !(
              selectedOption === "Create your own course" ||
              (selectedOption === "Create session from a previous session" &&
                selectedSession !== "") ||
              (selectedOption === "Create session from an existing course" &&
                selectedCourse !== "")
            )
          }
        />
      </div>
    </Modal>
  );
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    width: "520px                                             ",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    borderRadius: "8px",
    border: "1px solid #ccc",
    transform: "translate(-50%, -50%)",
  },
};

export default observer(Popup);
