import { useEffect } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";

import { CourseComponents, Footer, Navbar, Playlist } from "../../../components";
import { useStore } from "../../../store";

function Course() {
    const params = useParams();
    const { courseStore, userStore } = useStore();

    useEffect(() => {
        if (params.id) {
            courseStore.getCourseById(String(params.id));
        }
        userStore.getUser();
    }, []);

    return (
        <>
            <Navbar />

            <CourseComponents.CourseHeader type="course" />
            <CourseComponents.CourseStats />
            <CourseComponents.CourseNavBar type="course" />

            <CourseComponents.AboutCourse />
            <CourseComponents.CourseContent />
            <CourseComponents.CourseStories />
            <CourseComponents.CourseGoals />
            
            <Playlist />

            <CourseComponents.FAQs />

            <Footer />
        </>
    );
}

export default observer(Course);