import React from "react";

import styles from "./partners.module.css";
import {partners, partners_2} from '../../../../constants';

interface IProps {
    partnersCount: number;
}

const Partners: React.FC<IProps> = ({ partnersCount }) => {
    return (
        <div className={styles.partners_container}>
            <div className={styles.partners}>
                <h2>
                    We collaborate with leading universities and companies on <span>+1000</span> world-class courses
                </h2>

                <div className={styles.partners_logos}>
                    <div className={styles.partners_pictures}>
                        {partners.map((item) => (
                            <a key={item.id} href={item.link} target="_blank" rel="noreferrer">
                                <img
                                    alt="partner"
                                    loading="lazy"
                                    src={item.picture}
                                    width={150}
                                    style={{
                                        objectFit: 'contain',
                                    }}
                                />
                            </a>
                        ))}
                    </div>
                    <div className={styles.partners_pictures}>
                        {partners_2.map((item) => (
                            <a key={item.id} href={item.link} target="_blank" rel="noreferrer">
                                <img
                                    alt="partner"
                                    loading="lazy"
                                    src={item.picture}
                                    width={150}
                                    style={{
                                        objectFit: 'contain',
                                    }}
                                />
                            </a>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Partners;