import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import styles from "./payment.module.css";
import { Footer, Navbar, PaymentComponents } from "../../../components";
import { OtherCourses } from "../../../components/others/courses";
import { useStore } from "../../../store";
import { observer } from "mobx-react";

function Payment() {
    const params = useParams();
    const navigate = useNavigate();
    const { courseStore, userStore } = useStore();
    
    const token = localStorage.getItem('token');
    
    const [status, setStatus] = useState<1 | 2>(1);
    const [paymentMethod, setPaymentMethod] = useState<"PostWire" | "Onsite">("Onsite");

    useEffect(() => {
        if (!token) {
            navigate('/');
        }
        userStore.getUser();

        if (params.id) {
            courseStore.getTopRatedCourses();
            courseStore.getCohortById(params.id);
        }
    }, []);

    return (
        <div>
            <Navbar />
                
            <main>
                <div className={styles.payment_container}>
                    <PaymentComponents.PaymentPath />

                    <div className={styles.payment_content}>
                        <PaymentComponents.PaymentForm
                            status={status}
                            paymentMethod={paymentMethod}
                            setPaymentMethod={setPaymentMethod}
                        />
                        <PaymentComponents.PaymentDetails
                            status={status}
                            setStatus={setStatus}
                            paymentMethod={paymentMethod}
                        />
                    </div>
                </div>

                <OtherCourses />
            </main>

            <Footer />
        </div>
    );
}

export default observer(Payment);