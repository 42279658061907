import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../store";
import {
  Navbar,
  Loading,
  UploadImage,
  Input,
  TextEditor,
  Select as RSelect,
  Button,
  DateRange,
  TimeRange,
  LocationInput,
} from "src/components/shared";
import Select from "react-select";
import { Switch } from "antd";
import { getAllSessionsAndCourses, newSession } from "src/store/session";
import Popup from "./Popup";
import "../NewCourse/styles.css";

export default function NewSession() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { userStore, courseStore } = useStore();

  useEffect(() => {
    userStore.getUser().then(() => {
      const user = userStore.user;
      if (!user) {
        navigate("/login");
      }
    });
  }, []);

  const user = userStore.user;

  const [isPopupOpen, setIsPopupOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState<File | null>(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");
  const [syllabus, setSyllabus] = useState("");
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [startTime, setStartTime] = useState<any>();
  const [endTime, setEndTime] = useState<any>();
  const [schedule, setSchedule] = useState("");
  const [minPlace, setMinPlace] = useState("");
  const [maxPlace, setMaxPlace] = useState("");
  const [discount, setDiscount] = useState("");
  const [daysBeforeCancel, setDaysBeforeCancel] = useState("");
  const [price, setPrice] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [domains, setDomains] = useState([]);
  const [isOnline, setIsOnline] = useState<boolean>(true);
  const [location, setLocation] = useState<any>();
  const [sessions, setSessions] = useState([]);
  const [courses, setCourses] = useState([]);

  const difficulties = [
    { value: 1, label: "Beginner" },
    { value: 2, label: "Intermediate" },
    { value: 3, label: "Advanced" },
  ];

  const schedules = [
    { value: "morning", label: "Morning" },
    { value: "afternoon", label: "Afternoon" },
    { value: "evening", label: "Evening" },
  ];

  const handleTime = (date: string) => {
    return date.substr(11, 2);
  }

  useEffect(() => {
    if (id !== "new") {
      setIsPopupOpen(false);

      const session: any = sessions.find((session: any) => session.id === id);
      const course: any = courses.find((course: any) => course.id === id);
      console.log(course);
      if (session) {
        setImage(session.SessionImage)
        setTitle(session.title);
        setDescription(session.description);
        setStartDate(new Date(session.start));
        setEndDate(new Date(session.end));
        setStartTime(handleTime(session.startTime));
        setEndTime(handleTime(session.endTime));
        setMinPlace(session.minPlace);
        setMaxPlace(session.maxPlace);
        setDiscount(session.discount);
        setPrice(session.price);
        setDifficulty(session.difficulty);
        setSelectedDomains(
          session.domains.map((domain: any) => ({
            value: domain,
            label: domain,
          }))
        );
        // setSyllabus(session.syllabus);
        // setSchedule(session.schedule);
        // setDaysBeforeCancel(session.daysBeforeCancel);
        // setIsOnline(session.isOnline);
        // if (session.isOnline) {
        //   setLink(session.location);
        // } else {
        //   setLocation({ label: session.location });
        // }
      } else if (course) {        
        setImage(course.image)
        setTitle(course.title);
        setDescription(course.description);
        // setSyllabus(session.syllabus);
        // setMinPlace(course.minPlace);
        // setMaxPlace(course.maxPlace);
        setDiscount(course.discount);
        setPrice(course.price);
        setDifficulty(course.difficulty);
        setSelectedDomains(
          course.domains.map((domain: any) => ({
            value: domain.id,
            label: domain.name,
          }))
        );
      }
    } else {
      setIsPopupOpen(true);
    }
  }, [id]);

  useEffect(() => {
    setLoading(true);

    Promise.all([courseStore.getAllDomains(), getAllSessionsAndCourses()])
      .then(([domainsResponse, sessionsAndCoursesResponse]) => {
        if (domainsResponse !== null) {
          const domains = domainsResponse.map((domain: any) => ({
            value: domain.id,
            label: domain.name,
          }));
          setDomains(domains);
        }

        if (sessionsAndCoursesResponse !== null) {
          setSessions(sessionsAndCoursesResponse.sessions);
          setCourses(sessionsAndCoursesResponse.courses);
        }

        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const links = [{ path: "/mysessions", label: "My Sessions" }];

  const handleSave = async () => {
    const domains = selectedDomains;
    const session = {
      SessionImage: image,
      title,
      description,
      syllabus,
      start: startDate,
      end: endDate,
      startTime: new Date().setHours(startTime),
      endTime: new Date().setHours(endTime),
      schedule,
      minPlace,
      maxPlace,
      discount,
      price,
      difficulty,
      daysBeforeCancel,
      isOnline,
      location: location?.label,
      onlineSessionLink: link,
      teacherId: user.id,
    };

    const data = {
      session, domains
    }
    
    if (image?.lastModified) {
      await userStore.uploadPicture(image).then((response) => {
        session.SessionImage = response;
      });
    }

    console.log(session);
    await newSession(
      data
    ).then((response) => {
      if (response !== null) {
        navigate("/teaching");
      }
    });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div className="session-popup">
        <Popup isOpen={isPopupOpen} sessions={sessions} courses={courses} />
      </div>
      <Navbar />

      <div className="new-session">
        <div className="links">
          {links.map((link, index) => (
            <div className="link" key={index}>
              <Link to={link.path} className="label">
                {link.label}
              </Link>
              <div className="arrow">&gt;</div>
            </div>
          ))}
          <div className="active label">Add new session</div>
        </div>

        <h1>Add new session</h1>

        <div className="inputs">
          <div className="left-column">
            <div className="field">
              <label htmlFor="picture">Course banner</label>
              <div className="input-field">
                <UploadImage image={image} setImage={setImage} width="80%" />
              </div>
            </div>

            <div className="field">
              <div className="input-field">
                <div className="switch-container">
                  <Switch
                    defaultChecked={isOnline}
                    onChange={() => setIsOnline(!isOnline)}
                    style={{ backgroundColor: isOnline ? "#CA2D5D" : "" }}
                  />
                  <label htmlFor="link">{isOnline ? "isOnline" : "Offline"}</label>
                </div>
              </div>
            </div>

            {isOnline ? (
              <div className="field">
                <label htmlFor="link">Link to session</label>
                <div className="input-field">
                  <div className="input">
                    <Input
                      type="text"
                      label="Copy link to session"
                      fullWidth
                      value={link}
                      onChange={(e) => setLink(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="field">
                <label htmlFor="loaction">Location</label>
                <div className="input-field">
                  <div className="input">
                    <LocationInput
                      location={location}
                      setLocation={setLocation}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="field">
              <label htmlFor="title">Title</label>
              <div className="input-field">
                <div className="input">
                  <Input
                    type="text"
                    label="Title"
                    fullWidth
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="field">
              <label htmlFor="description">Description</label>
              <div className="input-field">
                <textarea
                  className="bio"
                  id="description"
                  placeholder="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>

            <div className="field">
              <label htmlFor="syllabus">Syllabus</label>
              <div className="input-field">
                <TextEditor text={syllabus} setText={setSyllabus} />
              </div>
            </div>

            <div className="field">
              <label htmlFor="domain">Domain</label>
              <div className="input-field">
                <div className="input">
                  <Select
                    isMulti
                    options={domains}
                    value={selectedDomains}
                    closeMenuOnSelect={false}
                    onChange={(e: any) => setSelectedDomains(e)}
                    placeholder="Select domain(s)"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="right-column">
            <div className="field">
              <label htmlFor="date">Date</label>
              <div className="input-field">
                <div className="input">
                  <DateRange
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                  />
                </div>
              </div>
            </div>

            <div className="field">
              <label htmlFor="schedule">Schedule</label>
              <div className="input-field">
                <div className="input">
                  <RSelect
                    label="Schedule"
                    fullWidth
                    options={schedules}
                    value={schedule}
                    onChange={(e) => setSchedule(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="field">
              <label htmlFor="date">Time</label>
              <div className="input-field">
                <div className="input">
                  <TimeRange
                    startTime={startTime}
                    setStartTime={setStartTime}
                    endTime={endTime}
                    setEndTime={setEndTime}
                  />
                </div>
              </div>
            </div>

            <div className="field">
              <label htmlFor="minPlace">Minimum place</label>
              <div className="input-field">
                <div className="input">
                  <Input
                    type="number"
                    label="minPlace"
                    fullWidth
                    value={minPlace}
                    onChange={(e) => setMinPlace(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="field">
              <label htmlFor="maxPlace">Maximum place</label>
              <div className="input-field">
                <div className="input">
                  <Input
                    type="number"
                    label="maxPlace"
                    fullWidth
                    value={maxPlace}
                    onChange={(e) => setMaxPlace(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="field">
              <label htmlFor="difficulty">Course difficulty</label>
              <div className="input-field">
                <div className="input">
                  <RSelect
                    label="Course difficulty"
                    fullWidth
                    options={difficulties}
                    value={difficulty}
                    onChange={(e) => setDifficulty(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="field">
              <label htmlFor="price">Price per person (TND)</label>
              <div className="input-field">
                <div className="input">
                  <Input
                    type="number"
                    fullWidth
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="field">
              <label htmlFor="discount">
                Possible discount pourcentage (%)
              </label>
              <div className="input-field">
                <div className="input">
                  <Input
                    type="number"
                    fullWidth
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="field">
              <label htmlFor="daysBeforeCancel">
                Days allowed before cancellation
              </label>
              <div className="input-field">
                <div className="input">
                  <Input
                    type="number"
                    fullWidth
                    value={daysBeforeCancel}
                    onChange={(e) => setDaysBeforeCancel(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="buttons">
          <Button
            text="Cancel"
            type="primary"
            customColor="#373737"
            width="400px"
            preventDefault={true}
            onClick={() => navigate(-1)}
          />
          <Button
            text="Save"
            type="primary"
            width="400px"
            preventDefault={true}
            onClick={handleSave}
          />
        </div>
      </div>
    </>
  );
}
