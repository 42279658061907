import styles from "./instructor.module.css";
import { useStore } from "../../../../store";
import { observer } from "mobx-react";
import env from "../../../../config/env";

function AboutInstructor() {
    const { courseStore } = useStore();

    const course : any = courseStore.courseDetails;
    const instructor : any = courseStore.instructorDetails;
    
    return (
        <div className={styles.course_instructor} id="about-instructor">
            <div className={styles.course_instructor_content}>
                <h3 className={styles.course_instructor_title}>About instructor</h3>

                <div className={styles.course_instructor_info}>
                    <div className={styles.course_instructor_image}>
                        <img
                            width={224}
                            height={224}
                            alt="avatar"
                            src={instructor?.image?.name ? env.IMAGE_API + instructor.image.name : "/images/we-code.jpeg"}
                        />
                    </div>

                    <div className={styles.course_instructor_details}>
                        <h4>{instructor?.fullName ? instructor?.fullName : course?.tutors?.fullName}</h4>
                        {instructor?.profession ? (
                            <h5>{instructor?.profession}</h5>
                        ) : null}
                        <hr />
                        {instructor?.description ? (
                            <p>{instructor.description}</p>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(AboutInstructor);
