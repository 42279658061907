import axios from "axios";
import axiosInstance from "../config/api";
import { makeAutoObservable, runInAction } from "mobx";

export interface Classroom {
  name: string;
  description: string;
  size: number;
  price: number;
  from: Date;
  to: Date;
  location: { lat: number; lng: number };
  address: object;
  logo: object;
  ownerName: string;
  album: object[];
  available: boolean;
  equipments: any[];
  typeEquipment: object;
  deleted: boolean;
  status: string;
  rating: number;
  ratingsCount: number;
  isOnline: boolean;
}

export async function getBookingList(
  id: string,
  limit: number,
  skip: number,
  tab: number
): Promise<any | null> {
  const token = localStorage.getItem("token");
  try {
    const response = await axiosInstance.get(
      `/Bookings/list?limit=${limit}&skip=${skip}&tab=${tab}&hostId=${id}&access_token=${token}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getMyList(
  limit: number,
  skip: number,
  tab: number
): Promise<any | null> {
  // Actual code
  const token = localStorage.getItem("token");
  try {
    const response = await axiosInstance.get(
      `/Rooms/getMyList?limit=${limit}&skip=${skip}&tab=${tab}&access_token=${token}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }

  // For testing
  // const token = "Z03A4BxVv8Ob2kVfQ6r2eT6iHQqxbb04xYAD7e8819AveInEmhjddPV5E0OYKKBf";
  // try {
  //     const response = await
  //     axios.create({
  //         baseURL: "https://api.wecode.land/server/api",
  //         timeout: 60000,
  //         }).get(
  //         `/Rooms/getMyList?limit=${limit}&skip=${skip}&tab=${tab}&access_token=${token}`
  //     );
  //     return response.data;
  // } catch (error) {
  //     console.log(error);
  //     return null;
  // }
}

export async function changeClassroomPrivacy(
  id: string,
  value: boolean
): Promise<any | null> {
  // Actual code
  const token = localStorage.getItem("token");
  try {
    const response = await axiosInstance.post(
      `/Rooms/displayMask?roomId=${id}&value=${value}&access_token=${token}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }

  // For testing
  // const token = "Z03A4BxVv8Ob2kVfQ6r2eT6iHQqxbb04xYAD7e8819AveInEmhjddPV5E0OYKKBf";
  // try {
  //     const response = await
  //     axios.create({
  //         baseURL: "https://api.wecode.land/server/api",
  //         timeout: 60000,
  //         }).post(
  //         `/Rooms/displayMask?roomId=${id}&value=${value}&access_token=${token}`
  //     );
  //     return response.data;
  // } catch (error) {
  //     console.log(error);
  //     return null;
  // }
}
