import React, { useEffect } from "react";

import styles from "./form.module.css";
import { Button, Input, Select } from "../../../../components";
import { validateEmail, validateName } from "../../../../utils/validation";
import { states } from "../../../../constants";
import PaymentMethods from "./PaymentMethods";

function ProgressItem({
  count,
  text,
  active,
}: {
  count: number;
  text: string;
  active?: boolean;
}) {
  return (
    <div className={styles.progress_item}>
      <div
        className={
          active
            ? styles.progress_item_count_active
            : styles.progress_item_count
        }
      >
        {active && <span>{count}</span>}
      </div>
      <span className={styles.progress_item_text}>{text}</span>
    </div>
  );
}

export default function PaymentForm({
  status,
  paymentMethod,
  setPaymentMethod,
}: {
  status: 1 | 2 | 3;
  paymentMethod: "PostWire" | "Onsite";
  setPaymentMethod: React.Dispatch<React.SetStateAction<"PostWire" | "Onsite">>;
}) {
  const [friendAdded, setFriendAdded] = React.useState<boolean>(false);
  const [agree, setAgree] = React.useState<boolean>(false);

  const [firstName, setFirstName] = React.useState<string>("");
  const [lastName, setLastName] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [streetAddress, setStreetAddress] = React.useState<string>("");
  const [city, setCity] = React.useState<string>("");
  const [zipCode, setZipCode] = React.useState<string>("");
  const [phone, setPhone] = React.useState<string>("");

  const [countryList, setCountryList] = React.useState([]);

  const [country, setCountry] = React.useState<string>("");
  const [friendCountry, setFriendCountry] = React.useState<string>("");

  const [countryState, setCountryState] = React.useState<string>("");
  const [friendCountryState, setFriendCountryState] =
    React.useState<string>("");

  const [stateList, setStateList] = React.useState([]);
  const [friendStateList, setFriendStateList] = React.useState([]);

  useEffect(() => {
    const countriesFromLocalStorage = localStorage.getItem("countries");
    if (countriesFromLocalStorage) {
      const countries = JSON.parse(countriesFromLocalStorage).map(
        (country: any) => {
          return { value: country.c_id, label: country.name };
        }
      );

      setCountryList(countries);
    }
  }, []);

  const handleOnChangeAgree = () => {
    setAgree(!agree);
  };

  const onCountryChange = (selectedCountryId: string) => {
    setCountryState("");
    setStateList([]);

    let selectedCountry: any = countryList.find(
      (country: any) =>
        country.label.toString() === selectedCountryId.toString()
    );

    if (selectedCountry) {
      setCountry(selectedCountryId);

      let statesListFromLocalStorage = localStorage.getItem("states");
      if (statesListFromLocalStorage) {
        let statesList = JSON.parse(statesListFromLocalStorage);

        let countryStatesList = statesList.filter((state: any) => {
          return state.country_id === +selectedCountry.value;
        });

        let mappedCountryStatesList = countryStatesList.map((state: any) => {
          return { value: state.id, label: state.name };
        });

        setStateList(mappedCountryStatesList);
      }
    }
  };

  const onFriendCountryChange = (selectedCountryId: string) => {
    setFriendCountryState("");
    setFriendStateList([]);

    let selectedCountry: any = countryList.find(
      (country: any) =>
        country.label.toString() === selectedCountryId.toString()
    );

    if (selectedCountry) {
      setFriendCountry(selectedCountryId);

      let statesListFromLocalStorage = localStorage.getItem("states");
      if (statesListFromLocalStorage) {
        let statesList = JSON.parse(statesListFromLocalStorage);

        let countryStatesList = statesList.filter((state: any) => {
          return state.country_id === +selectedCountry.value;
        });

        let mappedCountryStatesList = countryStatesList.map((state: any) => {
          return { value: state.id, label: state.name };
        });

        setFriendStateList(mappedCountryStatesList);
      }
    }
  };

  const onStateChange = (stateId: string) => {
    let statesListFromLocalStorage = localStorage.getItem("states");
    if (statesListFromLocalStorage) {
      let statesList = JSON.parse(statesListFromLocalStorage);
      let selectedState = statesList.find((state: any) => {
        return state.name === stateId;
      });

      setCountryState(selectedState.name);
    }
  };

  const onFriendStateChange = (stateId: string) => {
    let statesListFromLocalStorage = localStorage.getItem("states");
    if (statesListFromLocalStorage) {
      let statesList = JSON.parse(statesListFromLocalStorage);
      let selectedState = statesList.find((state: any) => {
        return state.name === stateId;
      });

      setFriendCountryState(selectedState.name);
    }
  };

  return (
    <div className={styles.payment_form}>
      <div className={styles.payment_form_container}>
        <div className={styles.payment_progress}>
          <ProgressItem
            active={status === 1 || status === 2}
            count={1}
            text="Billing Address"
          />
          <hr />
          <ProgressItem active={status === 2} count={2} text="Payment" />
          <hr />
          <ProgressItem count={3} text="Review" />
        </div>

        {status === 1 ? (
          <>
            <div className={styles.payment_form_row}>
              <div style={{ flex: 1 }}>
                <Input
                  fullWidth
                  label="First Name"
                  value={firstName}
                  validation={validateName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div style={{ flex: 1 }}>
                <Input
                  fullWidth
                  label="Last Name"
                  value={lastName}
                  validation={validateName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>

            <Input
              label="Email"
              value={email}
              validation={validateEmail}
              onChange={(e) => setEmail(e.target.value)}
            />

            <Input
              label="Street address"
              value={streetAddress}
              onChange={(e) => setStreetAddress(e.target.value)}
            />

            <div className={styles.payment_form_row}>
              <div className="w-full flex justify-start items-center space-x-2">
                <Select
                  fullWidth
                  label="Country"
                  value={country}
                  options={countryList}
                  onChange={(e) => onCountryChange(e.target.value)}
                />
                <Select
                  fullWidth
                  label="State/Province"
                  value={countryState}
                  options={stateList}
                  onChange={(e) => onStateChange(e.target.value)}
                />
              </div>
              <Input
                fullWidth
                label="City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>

            <div className={styles.payment_form_row}>
              <div style={{ flex: 1 }}>
                <Input
                  fullWidth
                  label="Zip/Postal Code"
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                />
              </div>
              <div style={{ flex: 1 }}>
                <Input
                  fullWidth
                  label="Phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </div>
            <div className={styles.checkbox_container}>
              <input
                type="checkbox"
                id="agree"
                name="agree"
                value="Bike"
                checked={agree}
                onChange={handleOnChangeAgree}
              />
              <label htmlFor="agree">Save my informations</label>
            </div>
          </>
        ) : (
          <PaymentMethods
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
          />
        )}
      </div>

      {status === 1 && !friendAdded ? (
        <div>
          <Button
            type="secondary"
            text="Add a friend to this course"
            onClick={() => setFriendAdded(true)}
            customColor="#000"
            icon="/images/icons/plus.svg"
          />
        </div>
      ) : status === 1 && friendAdded ? (
        <div className={styles.add_friend}>
          <h4>Add a friend</h4>
          <Button
            type="secondary"
            text="Delete"
            onClick={() => setFriendAdded(false)}
            customColor="#CA2D5D"
            icon="/images/icons/delete.svg"
          />
        </div>
      ) : null}

      {friendAdded && status === 1 && (
        <div className={styles.payment_form_container}>
          <div className={styles.payment_form_row}>
            <Input fullWidth label="First Name" value="" onChange={() => {}} />
            <Input fullWidth label="Last Name" value="" onChange={() => {}} />
          </div>

          <Input label="Email" value="" onChange={() => {}} />

          <Input label="Street address" value="" onChange={() => {}} />

          <div className={styles.payment_form_row}>
            <div className="w-full flex justify-start items-center space-x-2">
              <Select
                fullWidth
                label="Country"
                value={friendCountry}
                options={countryList}
                onChange={(e) => onFriendCountryChange(e.target.value)}
              />
              <Select
                fullWidth
                label="State/Province"
                value={friendCountryState}
                options={friendStateList}
                onChange={(e) => onFriendStateChange(e.target.value)}
              />
            </div>
            <Input fullWidth label="City" value="" onChange={() => {}} />
          </div>

          <div className={styles.payment_form_row}>
            <Input
              fullWidth
              label="Zip/Postal Code"
              value=""
              onChange={() => {}}
            />
            <Input fullWidth label="Phone" value="" onChange={() => {}} />
          </div>
          <div className={styles.checkbox_container}>
            <input
              type="checkbox"
              id="agree"
              name="agree"
              value="Bike"
              checked={agree}
              onChange={handleOnChangeAgree}
            />
            <label htmlFor="agree">Save my informations</label>
          </div>
        </div>
      )}
    </div>
  );
}
