import { observer } from "mobx-react";

import styles from "./content.module.css";
import ContentItem from "./ContentItem";
import { useStore } from "../../../../store";

function CourseContent() {
    const { courseStore } = useStore();

    const course : any = courseStore.courseDetails;

    return (
        <div className={styles.course_content} id="course-content">
            <div className={styles.course_content_content}>
                <div className={styles.course_content_info}>
                    <h4 className={styles.course_content_subtitle}>Course Content</h4>
                    <h3 className={styles.course_content_title}>Our courses incorporate a variety of learning materials, including videos, articles, and exams.</h3>
                </div>

                <div className={styles.course_content_items}>
                    {course?.syllabus?.blocks?.map((item: any, index: number) => (
                        item.type === "unstyled" ?
                            <ContentItem 
                                key={index}
                                course={item} />
                        : null
                    ))}
                </div>
            </div>
        </div>
    );
}

export default observer(CourseContent);