import { useEffect } from "react";

import { useStore } from "../../../store";
import { HomeComponents, Footer, Playlist } from "../../../components";

export default function Home() {
    const { userStore, courseStore } = useStore();
    
    useEffect(() => {
        courseStore.getDomains();
        courseStore.getCategories();
        courseStore.getInstructors(4);
        courseStore.getTopRatedCourses();
        courseStore.getUpcomingSessions();
        courseStore.getRecommendedSessions();
        userStore.getUser();
    }, []);
    
    return (
        <div>
            <HomeComponents.Header />
            
            <main>
                <HomeComponents.Partners partnersCount={250} />
                <HomeComponents.PopularCourses type="cohort" />
                <HomeComponents.Topics />
                <HomeComponents.CurrentSessions />
                <HomeComponents.Video />
                <HomeComponents.PopularCourses type="course" />
                <HomeComponents.Instructors />
                <Playlist />
                <HomeComponents.Steps />
                <HomeComponents.Blogs />
            </main>

            <Footer />
        </div>
    );
}