const faqs = [
    {
        id: "01",
        question: 'Are your courses certified?',
        answer: 'Yes, all our courses are certified. Our center is approved by the State and our certificates are recognized in Tunisia and internationally.'
    },
    {
        id: "02",
        question: 'Where to take the training?',
        answer: 'You can follow our training courses live or with one of our facilitators in your city.'
    },
    {
        id: "03",
        question: 'Who are your trainers?',
        answer: 'Our trainers are professionals in the sector: CEOs, directors, qualified engineers, university teachers... The selection of our instructors is a rigorous process based on ethics, pedagogy and expertise.'
    },
    {
        id: "04",
        question: 'What are the conditions of access to your sessions?',
        answer: 'The conditions of access to our sessions are detailed on each session available on the platform. You can also contact us for more information.'
    },
    {
        id: "05",
        question: 'How can I finance my training?',
        answer: 'You can benefit from payment facilities, scholarships or access our sessions within the framework of the Professional Training Tax.'
    },
];

export default faqs;