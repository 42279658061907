import React from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

import styles from "./details.module.css";
import { Button, Input } from "../../../../components";
import { useStore } from "../../../../store";
import env from "../../../../config/env";

function PaymentDetails({
    status,
    setStatus,
    paymentMethod
}: {
    status: 1 | 2,
    setStatus: React.Dispatch<React.SetStateAction<1 | 2>>
    paymentMethod: "PostWire" | "Onsite"
}) {
    const navigate = useNavigate();
    const { courseStore, userStore } = useStore();

    const [code, setCode] = React.useState<string>("");

    const course : any = courseStore.courseDetails;

    const tax = 0.20;

    const [agree, setAgree] = React.useState<boolean>(false);

    const handleOnChangeAgree = () => {
        setAgree(!agree);
    };

    const pay = async () => {
        if (status === 1) {
            setStatus(2);
        }
        else {
            if (!agree) {
                toast("Please check to acknowledge our Privacy & Terms Policy !");
            }
            else {
                await courseStore.payForCourse({
                    courseId: course.id,
                    paymentMethod: userStore.paymentMethod,
                    profileId: userStore.user?.id,
                    phoneNumber: userStore.user?.phone,
                    address: userStore.user?.governorate,
                    userToken: localStorage.getItem("token") || ""
                })
                .then(() => {
                    navigate("/enrolled");
                })
                .catch((err) => {
                    console.error(err);
                    toast("Something went wrong !");
                });
            }
        }
    }

    const useCode = async () => {
        await courseStore.subscribeWithCoupon({
            code: code,
            sessionId: course.id,
            studentId: userStore.user?.id,
        })
        .then(() => { })
        .catch((err) => {
            console.error(err);
            toast("Invalid Coupon !");
        });
    }

    return (
        <div className={styles.payment_details}>
            <ToastContainer theme="dark" />
            <div className={styles.course_details}>
                <img
                    src={`${env.IMAGE_API}${course?.sessionCourse?.image?.name}`}
                    alt={course?.title}
                />

                <div className={styles.course_info}>
                    <div className={styles.course_info_header}>
                        {course?.domains && (
                            <span className={styles.course_category}>{course?.domains[0] || ""}</span>
                        )}
                        <span className={styles.course_title}>{course?.title}</span>
                        <p>
                            {course?.description}
                        </p>
                    </div>

                    <div className={styles.course_footer}>
                        <div className={styles.course_instructor}>
                            <img
                                src="/images/we-code.jpeg"
                                alt={course?.tutors?.fullName}
                                className={styles.course_instructor_image}
                                width={20}
                                height={20}
                            />
                            <p className={styles.course_instructor_name}>{course?.tutors?.fullName}</p>
                        </div>
                        <p className={styles.course_price}>{course?.price} DT</p>
                    </div>
                </div>
            </div>

            <div className={styles.payment_billing}>
                <h4>Billing Summary</h4>

                <div className={styles.payment_billing_details}>
                    <div className={styles.payment_billing_details_item}>
                        <span>Subtotal</span>
                        <span>{course?.price} DT</span>
                    </div>
                    <div className={styles.payment_billing_details_item}>
                        <span>Discount</span>
                        <span>{course?.discount} DT</span>
                    </div>
                    <div className={styles.payment_billing_details_item}>
                        <span>Tax</span>
                        <span>{course?.price * tax} DT</span>
                    </div>

                    <span className={styles.promo_code_text}>
                        Do you have a promo code ?
                    </span>
                </div>

                <div className={styles.promo_code}>
                    <div style={{ flex: 1 }}>
                        <Input
                            fullWidth
                            label="Promo Code" 
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                        />
                    </div>
                    <Button
                        text="Apply"
                        onClick={useCode}
                    />
                </div>

                <hr />

                <div className={`${styles.payment_billing_details_item} ${styles.payment_billing_details_total}`}>
                    <span>Total</span>
                    <span>{(course?.price + course?.discount) + ((course?.price + course?.discount) * tax)} DT</span>
                </div>

                <div className={styles.checkbox_container}>
                    <input
                        type="checkbox"
                        id="agree"
                        name="agree"
                        value="Bike"
                        checked={agree}
                        onChange={handleOnChangeAgree}
                    />
                    <label htmlFor="agree">Please check to acknowledge our <a className={styles.privacy_link}>Privacy & Terms Policy</a></label>
                </div>

                <Button
                    text={`Pay ${(course?.price + course?.discount) + ((course?.price + course?.discount) * tax)} DT`}
                    onClick={() => pay()}
                />
            </div>
        </div>
    );
}

export default observer(PaymentDetails);