import React from "react";
import { observer } from "mobx-react";

import styles from "./courses.module.css";
import { Courses } from "../../../../components";
import { useStore } from "../../../../store";

const OtherCourses: React.FC = () => {
    const { courseStore } = useStore();
    
    return (
        <div className={styles.courses_container}>
            <div className={styles.courses}>
                <div className={styles.titles_container}>
                    <h1 className={styles.sub_title}>More courses</h1>
                    <h2 className={styles.title}>Other related courses</h2>
                </div>

                <div className={styles.courses_list}>
                    {courseStore.upcomingSessions.slice(0, 3)?.map((course: any, index) => (
                        <Courses.CourseItem
                            key={index}
                            type="cohort"
                            course={{
                                id: course?.id,
                                title: course?.title,
                                category: course?.domains[0],
                                description: course?.description,
                                image: course?.SessionImage?.name,
                                price: course?.price,
                                rating: course?.rating,
                                ratingCount: course?.ratingsCount,
                                provider: {
                                    name: course?.tutors?.fullName,
                                    logo: "/images/we-code.jpeg",
                                },
                                startTime: course?.startTime,
                                endTime: course?.endTime,
                                location: course?.isOnline ? "Online" : "Synergy Space",
                            }}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default observer(OtherCourses);