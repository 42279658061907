import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

import styles from "./filter.module.css";
import { Input, Select } from "../../../../components";
import { useStore } from "../../../../store";
import env from "../../../../config/env";

type CountryModel = {
  id: string;
  name: string;
};

type StateModel = {
  id: string;
  name: string;
};

type Option = {
  value: string;
  label: string;
};

const SearchedCourses: React.FC = () => {
  const { courseStore } = useStore();

  const [loading, setLoading] = useState<boolean>(false);
  const [price, setPrice] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [categories, setCategories] = useState<string[]>([]);
  const [mapsLocation, setMapsLocation] = useState<Option | null>(null);

  const [country, setCountry] = React.useState<string>("");
  const [countryState, setCountryState] = React.useState<string>("");
  const [countryList, setCountryList] = React.useState<CountryModel[]>([]);
  const [stateList, setStateList] = React.useState<StateModel[]>([]);

  const filter = courseStore.filter;

  useEffect(() => {
    if (filter) {
      setSearch(filter.search);
      setPrice(filter.priceMax);
      setLocation(filter.location);
      if (filter.categoryId.length > 0) {
        setCategories([filter.categoryId]);
      }
    }
    const countriesFromLocalStorage = localStorage.getItem("countries");
    if (countriesFromLocalStorage) {
      const countries = JSON.parse(countriesFromLocalStorage).map(
        (country: any) => {
          return { value: country.c_id, label: country.name };
        }
      );

      setCountryList(countries);
    }
  }, []);

  const handleSearch = (event: any) => {
    event.preventDefault();

    setLoading(true);

    courseStore
      ?.searchCourses(6, {
        search: search,
        priceMax: price,
        location: mapsLocation?.label || '',
        categoryId: categories[0],
      })
      .then(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const selectCategory = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = e.target;
    if (checked) {
      setCategories([...categories, value]);
    } else {
      setCategories(categories.filter((category) => category !== value));
    }
  };

  const onCountryChange = (selectedCountryId: string) => {
    setCountryState("");
    setStateList([]);

    let selectedCountry: any = countryList.find(
      (country: any) =>
        country.label.toString() === selectedCountryId.toString()
    );

    if (selectedCountry) {
      setCountry(selectedCountryId);

      let statesListFromLocalStorage = localStorage.getItem("states");
      if (statesListFromLocalStorage) {
        let statesList = JSON.parse(statesListFromLocalStorage);

        let countryStatesList = statesList.filter((state: any) => {
          return state.country_id === +selectedCountry.value;
        });

        let mappedCountryStatesList = countryStatesList.map((state: any) => {
          return { value: state.id, label: state.name };
        });

        setStateList(mappedCountryStatesList);
      }
    }
  };

  const onPlaceSelected = async (value: Option | null) => {
    setMapsLocation(value);
  };

  return (
    <aside className={styles.filter_section}>
      <h3>Filters</h3>
      <form id="filter-form" onSubmit={handleSearch}>
        {/* Search Input */}
        <div className={styles.filter_group}>
          <Input
            // fullWidth
            label="Search"
            value={search}
            onChange={(e: any) => setSearch(e.target.value)}
          />
        </div>

        {/* Categories */}
        <div className={styles.filter_group}>
          <h4>Categories</h4>
          <div className={styles.filter_options}>
            {courseStore?.domains.map((category: any, index) => (
              <label htmlFor={category?.name} key={index}>
                <input
                  type="checkbox"
                  id={category?.name}
                  name={category?.name}
                  value={category?.id}
                  onChange={selectCategory}
                />
                {category?.name}
              </label>
            ))}
          </div>
        </div>

        {/* Price Range */}
        <div className={styles.filter_group}>
          <h4>Price Range</h4>
          <div className={styles.filter_options}>
            <input
              type="range"
              min="40"
              max="2000"
              value={price}
              onChange={(e) => setPrice(Number(e.target.value))}
            />
            <div className={styles.price_range}>
              <span>40DT</span>
              <span>2000DT</span>
            </div>
          </div>
        </div>

        <div className={styles.filter_group}>
          <h4>Location</h4>
          <div className="w-full">
            <GooglePlacesAutocomplete
              apiKey={env.GOOGLE_MAPS_API_KEY}
              selectProps={{
                onChange: onPlaceSelected,
                placeholder: "Your city",
                value: mapsLocation || undefined,
                styles: {
                  input: (provided) => ({
                    ...provided,
                    height: "100%",
                  }),

                  dropdownIndicator: (provided) => ({
                    display: "none",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                  valueContainer: (provided) => ({
                    ...provided,
                  }),
                  control: (provided) => ({
                    ...provided,
                    border: "1px solid #cad4e0",
                    borderRadius: "8px",
                    height: "3rem",
                  }),
                },
              }}
            />
          </div>
        </div>

        {/* Apply Button */}
        <div className={styles.filter_group}>
          <button
            type="submit"
            disabled={loading}
            className={
              loading ? styles.search_button_disabled : styles.search_button
            }
          >
            Apply Filters
          </button>
        </div>
      </form>
    </aside>
  );
};

export default observer(SearchedCourses);
