import React from "react";

import styles from "./topics.module.css";
import TopicItem from "./TopicItem";

const Topics: React.FC = () => {
  return (
    <div className={styles.topics_container}>
      <div className={styles.topics}>
        <div className={styles.topics_heading}>
          <span>Do you want to have more details about our categories ?</span>
          <h2>A wide range of categories customised for you</h2>
        </div>

        <div className={styles.topics_list}>
          <TopicItem
            title="Articifial Intelligence"
            description="Dive into the fascinating realm of Artificial Intelligence learning, where innovation meets knowledge. Acquire cutting-edge skills and explore the limitless potential of AI technology."
            bgColor="#F4EBFF"
            icon="/images/icons/user-experience.svg"
          />

          <TopicItem
            title="Data Science"
            description="Embark on a transformative journey in Data Science, where analytics and insights converge. Acquire advanced skills and explore the boundless opportunities within the realm of data."
            bgColor="#E0EAFF"
            icon="/images/icons/development.svg"
          />

          <TopicItem
            title="Cyber Security"
            description="Immerse yourself in the dynamic field of cybersecurity, where safeguarding digital landscapes is paramount. Acquire specialized skills and navigate the ever-evolving challenges of securing information in cyberspace."
            bgColor="#FCE7F6"
            icon="/images/icons/marketing.svg"
          />
        </div>
      </div>
    </div>
  );
};

export default Topics;
