import { CourseStore } from "./course";
import { UserStore } from "./user";

export type RootStore = {
	userStore: UserStore;
	courseStore: CourseStore;
};

const rootStore: RootStore = {
	userStore: new UserStore(),
	courseStore: new CourseStore(),
};

export default rootStore;